import React from "react";

import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
import Box from "@mui/system/Box";
import LogoWhite from "../LogoWhite";

function About() {
  return (
    <Container sx={{ my: 2 }}>
      <Typography align="center" variant="h1" sx={{ mb: 2 }}>
        Über Uns
      </Typography>
      <Grid
        container
        // spacing={3}
        component={Paper}
        sx={{
          borderRadius: " 7% 0 7% 0",
          overflow: "hidden",
        }}
      >
        <Grid
          item
          xs={12}
          md={7}
          sx={{
            padding: 3,
            color: "white",
            // background: "linear-gradient(to left, #000428, #004e92)",
            background: "linear-gradient(310deg, #000428 0%, #004e92 59%)",
            // "linear-gradient(310deg, rgba(0,0,0,1) 0%, rgba(0,84,150,1) 59%)",
          }}
        >
          {/* <p>
            Wichtig war und ist uns, dass wir die selbe Sprachen sprechen
            können, wir kommen selbst aus der Fertigung und haben am eigenen
            Leibe die Erfahrungen machen müssen und möchten mit unserer Seite
            nun helfen.
          </p> */}
          {/* <br /> */}
          <p>
            Wir sind ein junges und dynamisches Unternehmen, das eine innovative
            Plattform für Einkäufer und Fertiger aus den Bereichen- Metall-,
            Holz-, Kunststoffverarbeitung und 3D-Druck erstellt hat.
          </p>
          <br />
          <p>
            Die Idee für die Entstehung dieser Plattform wurde gelegt, nachdem
            wir als Fertigungsunternehmen über Jahre hinweg Bauteile
            konstruiert, analysiert und den gesamten Produktentstehungsprozess
            digital begleitet haben. Während dieser Zeit haben wir festgestellt,
            dass die Bestell- und Einkaufsprozesse optimiert werden könnten.
          </p>
          <br />
          <p>
            Unser Ziel ist es, dass Sie als Fertiger mit wenigen Klicks ihre
            freien Kapazitäten oder Sie als Einkäufer, ihre benötigten Aufträge
            online stellen können. Wir sind sicher, dass Sie so die Auslastung
            ihres Unternehmens schnell und unkompliziert optimieren werden.
          </p>
          <br />
          <p>
            Unternehmen, die nachhaltig und umweltfreundlich arbeiten, haben wir
            nach Prüfung mit einem Grünem Span gekennzeichnet.
          </p>
          <br />
          <p>
            Aktuell arbeiten wir mit unserer Plattform in Deutschland,
            Österreich oder der Schweiz. Wir haben in diesen Ländern aufgrund
            unserer langjährigen Erfahrung viele Kontakte in der Branche, die
            wir gerne einsetzen werden, um Sie als Einkäufer oder Fertiger
            zusammen zu bringen.
          </p>
          <br />
          <p>
            Durch unsere Erfahrung in der Branche besteht unser Team aus
            erfahrenen und engagierten Fachleuten, die Ihnen jederzeit zur
            Verfügung stehen. Wir setzen uns dafür ein, dass Sie stets eine
            positive Erfahrung mit Order-Scout machen und zufrieden sind.
          </p>
        </Grid>
        <Grid item xs={12} md={5}>
          <Box
            sx={{
              minWidth: "100%",
              minHeight: "100%",
              backgroundPosition: "right",
              backgroundImage: "url(/images/CNCBG.jpg)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "center",
                padding: "2em",
                margin: "1em",
                borderRadius: "1em",
                backgroundColor: "rgba(0,0,0,0.3)",
              }}
            >
              <LogoWhite width="80%" />
              <Typography
                align="center"
                sx={{
                  color: "white",
                  fontSize: { xs: "1.3rem", md: "1.5rem", lg: "2rem" },
                }}
              >
                {"Das Cockpit der CNC-Fertigung"}
              </Typography>
            </div>
          </Box>
        </Grid>
      </Grid>
    </Container>
  );
}

export default About;
